/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-content #bodyView .checkbox,
.altai-theme-admin #bodyView .checkbox {
  margin-top: 0;
  margin-bottom: 0;
}
.altai-theme-content #bodyView .checkbox label,
.altai-theme-admin #bodyView .checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
  min-height: 1.5em;
  line-height: 1.5em;
  padding-left: 0px;
  font-family: inherit;
  text-transform: none;
  font-size: inherit;
  cursor: pointer;
}
.altai-theme-content #bodyView .checkbox label::before,
.altai-theme-admin #bodyView .checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  left: 0;
  margin-left: -35px;
  border: 1px solid gray;
  border-radius: 3px;
  background-color: #fff;
  font-size: 90%;
}
.altai-theme-content #bodyView .checkbox label::after,
.altai-theme-admin #bodyView .checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  left: 0;
  top: 0;
  line-height: 1.5em;
  margin-left: -34px;
  padding-left: 2px;
  padding-top: 1px;
  font-size: 90%;
  color: white;
}
.altai-theme-content #bodyView .checkbox input[type="checkbox"],
.altai-theme-admin #bodyView .checkbox input[type="checkbox"],
.altai-theme-content #bodyView .checkbox input[type="radio"],
.altai-theme-admin #bodyView .checkbox input[type="radio"] {
  opacity: 0;
  z-index: 1;
}
.altai-theme-content #bodyView .checkbox input[type="checkbox"]:checked + label::after,
.altai-theme-admin #bodyView .checkbox input[type="checkbox"]:checked + label::after,
.altai-theme-content #bodyView .checkbox input[type="radio"]:checked + label::after,
.altai-theme-admin #bodyView .checkbox input[type="radio"]:checked + label::after {
  font-family: 'FontAwesome';
  content: "\f00c";
}
.altai-theme-content #bodyView .checkbox input[type="checkbox"]:indeterminate + label::after,
.altai-theme-admin #bodyView .checkbox input[type="checkbox"]:indeterminate + label::after,
.altai-theme-content #bodyView .checkbox input[type="radio"]:indeterminate + label::after,
.altai-theme-admin #bodyView .checkbox input[type="radio"]:indeterminate + label::after {
  display: block;
  content: " ";
  width: 10px;
  height: 3px;
  background-color: #555555;
  border-radius: 2px;
  margin-left: -16.5px;
  margin-top: 7px;
}
.altai-theme-content #bodyView .checkbox input[type="checkbox"]:disabled + label,
.altai-theme-admin #bodyView .checkbox input[type="checkbox"]:disabled + label,
.altai-theme-content #bodyView .checkbox input[type="radio"]:disabled + label,
.altai-theme-admin #bodyView .checkbox input[type="radio"]:disabled + label {
  opacity: 0.65;
}
.altai-theme-content #bodyView .checkbox input[type="checkbox"]:disabled + label::before,
.altai-theme-admin #bodyView .checkbox input[type="checkbox"]:disabled + label::before,
.altai-theme-content #bodyView .checkbox input[type="radio"]:disabled + label::before,
.altai-theme-admin #bodyView .checkbox input[type="radio"]:disabled + label::before {
  background-color: white;
  cursor: not-allowed;
}
.altai-theme-content #bodyView .checkbox.checkbox-circle label::before,
.altai-theme-admin #bodyView .checkbox.checkbox-circle label::before {
  border-radius: 50%;
}
.altai-theme-content #bodyView .checkbox.checkbox-inline,
.altai-theme-admin #bodyView .checkbox.checkbox-inline {
  margin-top: 0;
}
.altai-theme-content #bodyView .checkbox-primary input[type="checkbox"]:checked + label::before,
.altai-theme-admin #bodyView .checkbox-primary input[type="checkbox"]:checked + label::before,
.altai-theme-content #bodyView .checkbox-primary input[type="radio"]:checked + label::before,
.altai-theme-admin #bodyView .checkbox-primary input[type="radio"]:checked + label::before {
  background-color: red;
  border-color: red;
}
.altai-theme-content #bodyView .checkbox-primary input[type="checkbox"]:checked + label::after,
.altai-theme-admin #bodyView .checkbox-primary input[type="checkbox"]:checked + label::after,
.altai-theme-content #bodyView .checkbox-primary input[type="radio"]:checked + label::after,
.altai-theme-admin #bodyView .checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff;
}
.altai-theme-content #bodyView .checkbox-danger input[type="checkbox"]:checked + label::before,
.altai-theme-admin #bodyView .checkbox-danger input[type="checkbox"]:checked + label::before,
.altai-theme-content #bodyView .checkbox-danger input[type="radio"]:checked + label::before,
.altai-theme-admin #bodyView .checkbox-danger input[type="radio"]:checked + label::before {
  background-color: orange;
  border-color: orange;
}
.altai-theme-content #bodyView .checkbox-danger input[type="checkbox"]:checked + label::after,
.altai-theme-admin #bodyView .checkbox-danger input[type="checkbox"]:checked + label::after,
.altai-theme-content #bodyView .checkbox-danger input[type="radio"]:checked + label::after,
.altai-theme-admin #bodyView .checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff;
}
.altai-theme-content #bodyView .checkbox-info input[type="checkbox"]:checked + label::before,
.altai-theme-admin #bodyView .checkbox-info input[type="checkbox"]:checked + label::before,
.altai-theme-content #bodyView .checkbox-info input[type="radio"]:checked + label::before,
.altai-theme-admin #bodyView .checkbox-info input[type="radio"]:checked + label::before {
  background-color: #0068ff;
  border-color: #0068ff;
}
.altai-theme-content #bodyView .checkbox-info input[type="checkbox"]:checked + label::after,
.altai-theme-admin #bodyView .checkbox-info input[type="checkbox"]:checked + label::after,
.altai-theme-content #bodyView .checkbox-info input[type="radio"]:checked + label::after,
.altai-theme-admin #bodyView .checkbox-info input[type="radio"]:checked + label::after {
  color: #fff;
}
.altai-theme-content #bodyView .checkbox-warning input[type="checkbox"]:checked + label::before,
.altai-theme-admin #bodyView .checkbox-warning input[type="checkbox"]:checked + label::before,
.altai-theme-content #bodyView .checkbox-warning input[type="radio"]:checked + label::before,
.altai-theme-admin #bodyView .checkbox-warning input[type="radio"]:checked + label::before {
  background-color: yellow;
  border-color: yellow;
}
.altai-theme-content #bodyView .checkbox-warning input[type="checkbox"]:checked + label::after,
.altai-theme-admin #bodyView .checkbox-warning input[type="checkbox"]:checked + label::after,
.altai-theme-content #bodyView .checkbox-warning input[type="radio"]:checked + label::after,
.altai-theme-admin #bodyView .checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff;
}
.altai-theme-content #bodyView .checkbox-success input[type="checkbox"]:checked + label::before,
.altai-theme-admin #bodyView .checkbox-success input[type="checkbox"]:checked + label::before,
.altai-theme-content #bodyView .checkbox-success input[type="radio"]:checked + label::before,
.altai-theme-admin #bodyView .checkbox-success input[type="radio"]:checked + label::before {
  background-color: green;
  border-color: green;
}
.altai-theme-content #bodyView .checkbox-success input[type="checkbox"]:checked + label::after,
.altai-theme-admin #bodyView .checkbox-success input[type="checkbox"]:checked + label::after,
.altai-theme-content #bodyView .checkbox-success input[type="radio"]:checked + label::after,
.altai-theme-admin #bodyView .checkbox-success input[type="radio"]:checked + label::after {
  color: #fff;
}
.altai-theme-content #bodyView .checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.altai-theme-admin #bodyView .checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.altai-theme-content #bodyView .checkbox-primary input[type="radio"]:indeterminate + label::before,
.altai-theme-admin #bodyView .checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: red;
  border-color: red;
}
.altai-theme-content #bodyView .checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.altai-theme-admin #bodyView .checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.altai-theme-content #bodyView .checkbox-primary input[type="radio"]:indeterminate + label::after,
.altai-theme-admin #bodyView .checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}
.altai-theme-content #bodyView .checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.altai-theme-admin #bodyView .checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.altai-theme-content #bodyView .checkbox-danger input[type="radio"]:indeterminate + label::before,
.altai-theme-admin #bodyView .checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: orange;
  border-color: orange;
}
.altai-theme-content #bodyView .checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.altai-theme-admin #bodyView .checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.altai-theme-content #bodyView .checkbox-danger input[type="radio"]:indeterminate + label::after,
.altai-theme-admin #bodyView .checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}
.altai-theme-content #bodyView .checkbox-info input[type="checkbox"]:indeterminate + label::before,
.altai-theme-admin #bodyView .checkbox-info input[type="checkbox"]:indeterminate + label::before,
.altai-theme-content #bodyView .checkbox-info input[type="radio"]:indeterminate + label::before,
.altai-theme-admin #bodyView .checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #0068ff;
  border-color: #0068ff;
}
.altai-theme-content #bodyView .checkbox-info input[type="checkbox"]:indeterminate + label::after,
.altai-theme-admin #bodyView .checkbox-info input[type="checkbox"]:indeterminate + label::after,
.altai-theme-content #bodyView .checkbox-info input[type="radio"]:indeterminate + label::after,
.altai-theme-admin #bodyView .checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}
.altai-theme-content #bodyView .checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.altai-theme-admin #bodyView .checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.altai-theme-content #bodyView .checkbox-warning input[type="radio"]:indeterminate + label::before,
.altai-theme-admin #bodyView .checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: yellow;
  border-color: yellow;
}
.altai-theme-content #bodyView .checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.altai-theme-admin #bodyView .checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.altai-theme-content #bodyView .checkbox-warning input[type="radio"]:indeterminate + label::after,
.altai-theme-admin #bodyView .checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}
.altai-theme-content #bodyView .checkbox-success input[type="checkbox"]:indeterminate + label::before,
.altai-theme-admin #bodyView .checkbox-success input[type="checkbox"]:indeterminate + label::before,
.altai-theme-content #bodyView .checkbox-success input[type="radio"]:indeterminate + label::before,
.altai-theme-admin #bodyView .checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: green;
  border-color: green;
}
.altai-theme-content #bodyView .checkbox-success input[type="checkbox"]:indeterminate + label::after,
.altai-theme-admin #bodyView .checkbox-success input[type="checkbox"]:indeterminate + label::after,
.altai-theme-content #bodyView .checkbox-success input[type="radio"]:indeterminate + label::after,
.altai-theme-admin #bodyView .checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff;
}
.altai-theme-content #bodyView .radio label,
.altai-theme-admin #bodyView .radio label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
  line-height: 1.5em;
  cursor: pointer;
}
.altai-theme-content #bodyView .radio label::before,
.altai-theme-admin #bodyView .radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  left: 0;
  margin-left: -40px;
  border: 1px solid gray;
  border-radius: 50%;
  background-color: #fff;
  font-size: 90%;
}
.altai-theme-content #bodyView .radio label::after,
.altai-theme-admin #bodyView .radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 0.97em;
  height: 0.97em;
  left: 0.31em;
  top: 0.25em;
  margin-left: -34px;
  border-radius: 50%;
  background-color: white;
  font-size: 90%;
}
.altai-theme-content #bodyView .radio input[type="radio"],
.altai-theme-admin #bodyView .radio input[type="radio"] {
  opacity: 0;
  z-index: 1;
}
.altai-theme-content #bodyView .radio input[type="radio"]:disabled + label,
.altai-theme-admin #bodyView .radio input[type="radio"]:disabled + label {
  opacity: 0.65;
}
.altai-theme-content #bodyView .radio input[type="radio"]:disabled + label::before,
.altai-theme-admin #bodyView .radio input[type="radio"]:disabled + label::before {
  cursor: not-allowed;
}
.altai-theme-content #bodyView .radio.radio-inline,
.altai-theme-admin #bodyView .radio.radio-inline {
  margin-top: 0;
}
.altai-theme-content #bodyView .radio-primary input[type="radio"] + label::after,
.altai-theme-admin #bodyView .radio-primary input[type="radio"] + label::after {
  background-color: white;
}
.altai-theme-content #bodyView .radio-primary input[type="radio"]:checked + label::before,
.altai-theme-admin #bodyView .radio-primary input[type="radio"]:checked + label::before {
  border-color: red;
}
.altai-theme-content #bodyView .radio-primary input[type="radio"]:checked + label::after,
.altai-theme-admin #bodyView .radio-primary input[type="radio"]:checked + label::after {
  background-color: red;
}
.altai-theme-content #bodyView .radio-danger input[type="radio"] + label::after,
.altai-theme-admin #bodyView .radio-danger input[type="radio"] + label::after {
  background-color: white;
}
.altai-theme-content #bodyView .radio-danger input[type="radio"]:checked + label::before,
.altai-theme-admin #bodyView .radio-danger input[type="radio"]:checked + label::before {
  border-color: orange;
}
.altai-theme-content #bodyView .radio-danger input[type="radio"]:checked + label::after,
.altai-theme-admin #bodyView .radio-danger input[type="radio"]:checked + label::after {
  background-color: orange;
}
.altai-theme-content #bodyView .radio-info input[type="radio"] + label::after,
.altai-theme-admin #bodyView .radio-info input[type="radio"] + label::after {
  background-color: white;
}
.altai-theme-content #bodyView .radio-info input[type="radio"]:checked + label::before,
.altai-theme-admin #bodyView .radio-info input[type="radio"]:checked + label::before {
  border-color: #0068ff;
}
.altai-theme-content #bodyView .radio-info input[type="radio"]:checked + label::after,
.altai-theme-admin #bodyView .radio-info input[type="radio"]:checked + label::after {
  background-color: #0068ff;
}
.altai-theme-content #bodyView .radio-warning input[type="radio"] + label::after,
.altai-theme-admin #bodyView .radio-warning input[type="radio"] + label::after {
  background-color: white;
}
.altai-theme-content #bodyView .radio-warning input[type="radio"]:checked + label::before,
.altai-theme-admin #bodyView .radio-warning input[type="radio"]:checked + label::before {
  border-color: yellow;
}
.altai-theme-content #bodyView .radio-warning input[type="radio"]:checked + label::after,
.altai-theme-admin #bodyView .radio-warning input[type="radio"]:checked + label::after {
  background-color: yellow;
}
.altai-theme-content #bodyView .radio-success input[type="radio"] + label::after,
.altai-theme-admin #bodyView .radio-success input[type="radio"] + label::after {
  background-color: white;
}
.altai-theme-content #bodyView .radio-success input[type="radio"]:checked + label::before,
.altai-theme-admin #bodyView .radio-success input[type="radio"]:checked + label::before {
  border-color: green;
}
.altai-theme-content #bodyView .radio-success input[type="radio"]:checked + label::after,
.altai-theme-admin #bodyView .radio-success input[type="radio"]:checked + label::after {
  background-color: green;
}
.altai-theme-content #bodyView input[type="checkbox"].styled:checked + label:after,
.altai-theme-admin #bodyView input[type="checkbox"].styled:checked + label:after,
.altai-theme-content #bodyView input[type="radio"].styled:checked + label:after,
.altai-theme-admin #bodyView input[type="radio"].styled:checked + label:after {
  font-family: 'FontAwesome';
  content: "\f00c";
}
.altai-theme-content #bodyView input[type="checkbox"] .styled:checked + label::before,
.altai-theme-admin #bodyView input[type="checkbox"] .styled:checked + label::before,
.altai-theme-content #bodyView input[type="radio"] .styled:checked + label::before,
.altai-theme-admin #bodyView input[type="radio"] .styled:checked + label::before {
  color: #fff;
}
.altai-theme-content #bodyView input[type="checkbox"] .styled:checked + label::after,
.altai-theme-admin #bodyView input[type="checkbox"] .styled:checked + label::after,
.altai-theme-content #bodyView input[type="radio"] .styled:checked + label::after,
.altai-theme-admin #bodyView input[type="radio"] .styled:checked + label::after {
  color: #fff;
}
